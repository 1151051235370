<template>
  <v-layout row wrap align-start justify-start fill-height>
    <v-flex xs12>
      <v-btn
        v-for="(social, index) in social_media" :key="index"
        color="transparent"
        depressed fab small dark
        class="lowercase ma-0 mr-2"
        @click="openLink(social)"
      >
        <v-img
          :aspect-ratio="1"
          height="35"
          width="35"
          contain
          class="clickable ma-auto"
          :src="social.image"
          :lazy-src="social.image"
        ></v-img>
      </v-btn>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  name: 'SocialMedia',
  data () {
    return {
      social_media: [
        {
          image: require('@/assets/img/icons/socialmedia/facebook.png'),
          url: 'https://www.facebook.com/gorilaseguros/',
          event: 'goto_facebook'
        },
        {
          image: require('@/assets/img/icons/socialmedia/instagram.png'),
          url: 'https://www.instagram.com/gorilaseguros/',
          event: 'goto_instagram'
        },
        {
          image: require('@/assets/img/icons/socialmedia/youtube.png'),
          url: 'https://www.youtube.com/c/gorilaseguros',
          event: 'goto_youtube'
        },
        {
          image: require('@/assets/img/icons/socialmedia/linkedin.png'),
          url: 'https://www.linkedin.com/company/gorilaseguros/',
          event: 'goto_linkedin'
        },
        {
          image: require('@/assets/img/icons/socialmedia/twitter.png'),
          url: 'https://twitter.com/cuco_gorila',
          event: 'goto_twitter'
        }
      ]
    }
  },
  methods: {
    openLink (social) {
      this.$analytics(social.event)
      window.open(social.url, '_blank')
    }
  }
}
</script>

